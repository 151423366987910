import React from "react";

import * as Styles from "./Explanation404.styles";
import { Link } from "gatsby";

export default () => (
  <Styles.Container>
    <Styles.Columns>
      <Styles.TextColumn>
        <Styles.H1>Sidan kunde inte hittas</Styles.H1>
        <p>Denna sida finns inte längre</p>

        <Styles.GoBack>
          <Link to="">Gå till startsida</Link>
        </Styles.GoBack>
      </Styles.TextColumn>
    </Styles.Columns>
  </Styles.Container>
);
