import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Contact from "../components/general/Contact/Contact";
import Banner from "../components/general/Banner/Banner";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";
import Explanation404 from "../components/general/Explanation404/Explanation404";

export default () => (
  <div>
    <CustomHelmet
      title="Sidan kunde inte hittas - Platoon DJs"
      description="Sidan kunde inte hittas"
      keywords=""
      canonical="/404/"
    />
    <Header />
    <Banner
      fullHeight={false}
      videoUrl={video}
      tagline="Bästa DJs i Stockholm på"
      fadeTexts={[
        "50-årsfest",
        "Studentskiva",
        "Företagsevent",
        "Födelsedagsfest",
        "Bröllop",
        "Studentflak"
      ]}
    />
    <Explanation404 />
    <Contact />
    <Footer />
  </div>
);
